@import '../../../../../../../style/variables.scss';

.connect-now-wrapper {
  .title-wrapper {
    .title {
      font-size: 1.125rem;
      background-color: $default-white-color;
    }

    .close-icon {
      color: $font-secondary-color;
    }
  }

  .video-call-card {
    cursor: pointer;
    .video-call-icon {
      margin-right: 0.8125rem;
    }

    .videoButton {
      font-size: 1rem;
      align-self: center;

      .textBelow {
        font-size: 0.875rem;
        color: $or-text-color;
      }
    }
  }

  .alternative {
    text-align: center;
    margin-top: 0.3125rem;
    align-self: center;

    .alternative-text {
      padding-top: 0.625rem;
    }

    .textFor {
      font-size: 1rem;
      height: 2.625rem;
      align-self: center;
    }
  }

  .unavailable {
    background-color: $prescription-background-color;
    text-align: center;

    .button {
      font-size: 1rem;
      color: $prescription-color;
      background-color: $prescription-background-color;
      height: 2.625rem;
      align-self: center;
    }
  }

  .button-save {
    width: 100%;
    height: 2.625rem;
  }
}
