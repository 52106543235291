@import '../../../../../../../style/variables.scss';

.calendar-card-wrapper {
  padding: 0 0.5rem;
  .selected {
    background-color: $app-primary-color;
    margin-top: 0.3125rem;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
    padding-top: 0.4375rem;
    text-align: center;
    color: $default-white-color;
    cursor: pointer;
    font-size: 0.875rem;
  }

  .not-selected {
    background-color: $calendar-not-selected-color;
    color: $default-black-color;
    margin-top: 0.3125rem;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
    padding-top: 0.4375rem;
    text-align: center;
    cursor: pointer;
    font-size: 0.875rem;
  }

  .change-arrow {
    color: $app-primary-color;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0.313rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    .rotate-icon {
      transform: rotate(180deg);
    }
  }

  .day-format-styles {
    padding: 0.35rem;
    color: $or-text-color;
    font-size: 0.875rem;
  }
}
