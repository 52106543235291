@import '../../../../style/variables.scss';
@import '../../../../style/functions.scss';

.review-appointment-wrapper {
  width: 100%;
  .review-appointment-popup {
    margin-top: 0;
    max-height: 1rem;
  }
}
