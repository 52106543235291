@import '../../../../../src/style/variables.scss';
.appointment-container {
  .appointment-list {
    &::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    /* Optional: show position indicator in red */
    &::-webkit-scrollbar-thumb {
      background: #ff0000;
    }
  }

  .patient-details-grid {
    padding-top: 1.5rem !important;
  }
}

.appointment-grid {
  padding: 0rem;
}

.patient {
  background-color: $patient-card-background-color;
  // box-shadow: 0px -13px 13px rgba(193, 220, 228, 0.36),
  //   0px 13px 11px rgba(193, 220, 228, 0.25);
}
.line-code {
  background-color: $default-white-color;
  border: 0.0625rem solid $app-primary-color;
  border-radius: 0.1875rem;
}
.scanner-position {
  height: 1.75rem !important;
  width: 1.75rem !important;
  margin-left: 0.56rem;
  margin-top: 0.35rem;
}
.scanner-length {
  width: 0.3125rem !important;
}
.scannerSize {
  height: 2.1875rem !important;
  width: 2.5rem !important;
}
.newPatientsize {
  height: 2.1875rem !important;
  width: 7.5rem !important;
}
.newAppointmentsize {
  height: 2.1875rem !important;
  width: 12.5rem !important;
}
