@import '../../../../../../src/style/variables.scss';
.appointment-detail-wrapper {
  .border-none {
    border: none !important;
  }

  .patient-card {
    border-radius: 0.75rem;
    padding: 1rem;
    border-color: $grey-color-1;
  }

  .image-view {
    width: 40rem;
    // margin-bottom:40px !important;
    padding-bottom: 2.5rem !important;
    // background-color: red;
  }
  .card-displywh {
    width: 59.375rem;
    flex-wrap: wrap;
  }
  .card-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    padding-left: 2px;
  }
}

.patient-card-container {
  text-transform: capitalize;
}

.options-list-appointment-booking {
  height: 22rem;
}
