@import '../../../../style/variables.scss';

.common-confirmation-popup {
  background: $default-white-color 0% 0% no-repeat padding-box !important;
  border-radius: 0.45rem !important;
  opacity: 1 !important;
  max-width: 37rem !important;
  max-height: calc(100% - 0.5rem) !important;
  padding: 1rem;

  .popup-close-icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .appointment-avatar {
    background: $app-primary-color;
  }

  .popup-title {
    font-weight: 600;
    font-size: 1.1rem;
    color: $font-secondary-color;
    line-height: 1.75rem;
  }

  .popup-sub-title {
    font-size: 1.45rem;
    color: $font-secondary-color;
    font-weight: 600;
  }

  .message-text {
    font-weight: 300;
    font-size: 1.1rem;
    text-align: center;
    color: $default-black-color;
    margin-top: 1rem;
  }

  .appointment-cancel-button {
    height: 3rem;
    border-radius: 0.4rem;
    background: $vc-cancel-button-bg-color;
    width: 100%;
    font-weight: 600;
    font-size: 1.15rem;
    color: $app-primary-color;
  }

  .css-193tjys-MuiGrid-root {
    justify-content: center;
  }
}
