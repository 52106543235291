@import '../../../style/variables.scss';

.availability-buttons-wrapper {
  .book-appointment-button {
    font-size: 1.15rem !important;
    text-transform: capitalize;
    font-weight: 600;
    height: 2.35rem;
  }
}
