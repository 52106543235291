@import '../../../style/variables.scss';

.available-timings-wrapper {
  .available-timings-container {
    margin-top: 0.25rem;
    .timing-item {
      .section-1 {
        .img-sec {
          width: 1.5rem;
          height: 1.5rem;
        }
        .title {
          font-size: 1.1rem;
          color: $font-primary-color;
          opacity: 1;
          font-family: $text-font-regular;
          font-weight: 600;
        }
      }
      .subtitle {
        font-size: 1.15rem;
        color: $doctor-title-color;
        font-family: $text-font-regular;
        line-height: 2rem !important;
      }
    }
  }
}
