@import '../../../../../../../../../style/variables.scss';

.inside-medicine-grid {
  padding: 1rem;
  display: flex;

  border-radius: 0.4375rem;
  overflow-x: hidden;
  .medicine-info {
    border: 0.065rem solid $border-color-box-2;

    display: flex;
    align-items: center;
    .icon-grid {
      margin-left: 0.859rem;
      margin-top: 0.063rem;
      padding-top: 0.32rem;
    }
    .medicine-data {
      justify-content: space-between;
      margin-left: 1.023rem;
      width: 100%;
      padding-top: 0.42rem;
      padding-bottom: 0.5rem;
      padding-right: 1rem;

      .medic-name {
        text-transform: capitalize;
        font-weight: 600;
        overflow-wrap: anywhere;
      }
      .medic-box {
        margin-top: 0.2rem;
        display: flex;
        align-items: center;
        .inside-medicines {
          font-size: 1.125rem;
          margin-top: -0.004rem;
        }
        .medic-des {
          margin-top: 0.1rem;
          color: $or-text-color;
          font-size: 0.85rem !important;
          padding-top: 0.3rem;
        }
      }

      .align-center-to-box {
        display: flex;
        align-items: center;
      }
    }
  }

  .attachment-icons {
    cursor: pointer;
    margin: 0.5rem;
  }
}
.delete-strike-through {
  border: 0.063rem solid $deleted-border-strike-through !important;
  text-decoration: line-through;
  background-color: $deleted-medicine-background-color;
  text-decoration-color: $deleted-border-strike-through;
}
