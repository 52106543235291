@import '../../../../../../src/style/variables.scss';

.outlined-textfield {
  .MuiTextField-root {
    padding: 0;
  }
}
#step-two {
  .step-two-otp {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
}
#step-three {
  .step-three-user-icon {
    width: 2.5625rem;
    height: 2.5625rem;
  }
}

#step-four {
  .custom-auto-complete-wrapper {
    .MuiFormControl-root {
      height: 2.5rem;
    }
    .MuiFormControl-root {
      border: 0.0625rem solid $timeline-report-border-color_1;
      border-radius: 0.375rem;
      .MuiOutlinedInput-root {
        display: flex;
        flex-wrap: nowrap;
        height: 2.5rem;
        #step-four-patient-search {
          width: 9.375rem;
        }
      }
    }
  }
  .appointment-calendar-picker {
    border: 0.0625rem solid $timeline-report-border-color_1;
    border-radius: 0.375rem;
    .MuiCalendarPicker-viewTransitionContainer {
      overflow-y: hidden;
      .PrivatePickersCalendar-root-17 {
        min-height: 13.125rem;
      }
    }
  }
  .slot-chip {
    border-radius: 0.25rem;
    .MuiChip-label {
      padding-right: 0.3125rem;
      padding-left: 0.5rem;
    }
  }
}

#step-five {
  .outlined-textfield {
    .MuiOutlinedInput-root {
      padding: 0.3125rem;
      font-size: 16px !important;
    }
  }
}
