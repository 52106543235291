@import '../../../../../../../style/variables.scss';

.status-card-wrapper {
  padding-top: 0.35rem !important;
  .statusField {
    font-size: 0.75rem;
    width: 100%;
    border-radius: 0.3125rem;
    padding: 0.25rem;
    box-shadow: 0rem 0.3rem 0.3rem rgba(0, 0, 0, 0.25);
    .status-name {
      font-size: 0.8125rem;
      font-weight: 400;
    }
  }

  .onClickedStatus {
    border: 0.2rem solid $app-primary-color !important;
  }

  .count-bubble-view {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
  }
}
