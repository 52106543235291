@import '../../../style/variables.scss';
.mainWrapper {
  .headingWrapper {
    text-align: end;
    .headingGrid {
      display: flex;
      align-items: center;
      .imageGrid {
        .img {
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 0.5rem;
        }
      }
      .hospitalName {
        .name {
          font-weight: 600;
          font-size: 1rem;
        }
      }
    }
  }
  .QrWrapper {
    justify-content: center;
    text-align: end;
    .QrGrid {
      border: 0.0625rem solid $border-right-color;
      border-radius: 0.25rem;
      .Qr {
        width: 12rem;
        height: 12rem;
        border-radius: 0.5rem;
      }
    }
    .TextGrid {
      margin-top: 1rem;
      .branchName {
        text-align: center;
        font-weight: 600;
      }
    }
  }
  .ButtonWrapper {
    margin-top: 40px;
    .buttonGrid {
      height: 3.125rem;
      .button {
        height: 100%;
        width: 100%;
        .downloadFont {
          font-weight: 600;
          color: $white-background-color-1;
        }
      }
    }
  }
}
