@import './../../../../../../../style/variables.scss';

.add-vitals-wrapper {
  .icon {
    display: flex;
    justify-content: flex-end;
    padding: 0rem !important;
  }
  .add-vitals {
    font-size: 1.25rem;
  }

  .MuiDialog-paperWidthSm {
    background-color: $default-white-color;
    border-radius: 0.625rem;
  }

  .dialog-action-button-grid {
    justify-content: end;
    padding: 0.5rem 0.5rem;

    .save-button {
      margin-left: 0.625rem;
      width: auto;
    }
  }
  .vital-information-left-grid {
    font-size: 0.875rem;
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 100%;
    align-items: center;
  }

  .vital-information-left-grid-one {
    font-size: 0.875rem;
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 100%;
    margin-top: 0.3rem;
  }

  .vital-information-left-grid-two {
    font-size: 0.875rem;
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 100%;
  }

  .vital-others {
    margin-top: 0.9375rem;
    font-size: 0.875rem;

    .inside-vital {
      display: flex;
      margin: 0.3125rem 0rem;
      flex-direction: row;
      align-items: center;
    }
  }

  .bmi-color-negative {
    color: $app-error-color;
    padding-top: 0.3125rem;
  }
  .bmi-color-positive {
    color: $video-status-color;
    padding-top: 0.3125rem;
  }

  .value-name-unit-grid {
    align-self: center;
    margin-top: 0.3125rem;
    width: 100% !important;
    .value-name {
      text-transform: capitalize;
    }

    .unit-name {
      margin-left: 0.3rem;
      color: $or-text-color;
      font-size: 0.625rem;
    }
  }

  .bmi-grid {
    width: 100% !important;
    padding-top: 0.3125rem;
  }

  .blood-pressure-grid {
    margin: 0.7rem 0rem 0.3rem 0rem;
  }
  .left-values {
    align-items: flex-end;
    align-self: center;
    padding-left: 0.5rem;
  }

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0rem;
    margin-top: 0.1875rem;
  }
  .notes-input {
    margin-top: 0.9375rem;
  }
  .divider-grid {
    padding: 0.3rem;
  }

  .css-1uaxfgw-MuiButtonBase-root-MuiIconButton-root {
    padding: 0rem;
  }

  .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 0.875rem;
  }
  .array-label-name {
    text-transform: uppercase !important;
  }

  .array-label-name-grid {
    margin-top: 0.5rem !important;
  }

  .css-jkn7tm-MuiGrid-root {
    margin-top: 0.3125rem;
  }

  .css-m1b0vd-MuiDialogTitle-root {
    padding: 1rem 1.5rem 0.313rem 1.5rem !important;
  }
}
