@import '../../../../../../../style/variables.scss';

.appointment-details-wrapper {
  margin-top: 0.625rem;
  padding-top: 1rem;

  .single-app {
    padding: 0.3125rem;
  }

  .time-app {
    font-size: 0.875rem;
    font-weight: 600;
    color: $font-secondary-color;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    line-height: 1.125rem;
    padding-left: 1rem;
  }

  .insideDetails {
    height: 2.25rem !important;
    margin-top: 0.3125rem;
    border-radius: 0.4375rem;
    margin-bottom: 0.625rem;
    .search-bar {
      border: 0.0625rem solid $border-color-box !important;
      border-radius: 0.3125rem;
      background-color: $default-white-color;
    }
    ::placeholder {
      color: $font-secondary-color !important;
      font-size: 0.875rem;
      font-weight: 400;
    }

    .MuiFormControl-root {
      width: 100% !important;
      padding: 0%;
      margin: 0%;
      height: 2.25rem !important;
      border: 0.0625rem solid $border-color-box !important;
      justify-content: center;
      border-radius: 0.3125rem;

      .MuiAutocomplete-input {
        margin: 0;
        margin-left: 0.3125rem;
        padding: 0;
      }
      .MuiAutocomplete-input::placeholder {
        font-size: 0.875rem;
        color: $font-secondary-color;
      }

      .MuiSvgIcon-root {
        font-size: 0.875rem;
      }
    }
  }
  .MuiAutocomplete-input {
    flex: 1 !important;
    width: 100% !important;
    padding: 0.5rem, 0.625rem, 0.5rem, 0.625rem !important;
    height: 2.25rem !important;
  }

  .no-details-text {
    font-weight: bold;
    font-size: large;
  }

  .detailed {
    max-height: calc(100vh - 21.25rem);
    height: auto;
    overflow-y: auto;
  }

  .clinical-detailed {
    max-height: calc(100vh - 29rem);
    height: auto;
    overflow-y: auto;
  }

  .clinical-detailed-scroll {
    max-height: calc(100vh - 32rem);
  }
}
