@import '../../../../../../src/style/variables.scss';
.patient-details-card-wrapper {
  .letter {
    color: $app-error-color;
    width: 5rem;
    height: 5rem;
    font-size: 1.875rem;
  }
  .custom {
    font-weight: $app-tertiary-font-weight_1 !important;
    font-size: 1.375rem !important;
    color: $font-secondary-color !important;
  }
  .custom1 {
    font-weight: $app-font-weight;
  }

  .tag-online {
    font-weight: $app-tertiary-font-weight_1 !important;
    font-size: 0.8125rem !important;
    background-color: $background-color6;
    color: $video-status-color !important;
  }

  .tag-offline {
    font-weight: $app-tertiary-font-weight_1 !important;
    font-size: 0.8125rem !important;
    color: $prescription-color !important;
    background-color: $prescription-background-color;
  }
  .status-dot-grid {
    font-size: 0.75rem;
    font-family: $app_primary_font_regular;

    .status-dot {
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      margin-left: 0.25rem;
    }
  }
  .type-appoint {
    font-weight: $app-tertiary-font-weight !important;
    font-size: $text-secondary-font-size !important;
    color: $or-text-color !important;
  }
  .email-details {
    font-weight: $app-tertiary-font-weight !important;
    font-size: $text-tertiary-font-size_3 !important;
    color: $font-secondary-color !important;
  }
  .gender-detail {
    font-weight: $app-tertiary-font-weight !important;
    font-size: $text-tertiary-font-size_3 !important;
    color: $font-secondary-color !important;
  }
  .phone-details {
    font-weight: $app-tertiary-font-weight !important;
    font-size: 0.9375rem !important;
    color: $font-secondary-color !important;
  }
  .complain-head {
    font-weight: $app-tertiary-font-weight !important;
    font-size: 0.875rem !important;
    color: $or-text-color !important;
  }
  .custom-description {
    font-weight: $app-tertiary-font-weight !important;
    font-size: 1rem !important;
    color: $font-secondary-color !important;
    padding: 0rem, 0rem, 0rem, 1.4375rem !important;
  }
  .back-color {
    background-color: $grey-color-5;
  }
}
.patient-wrapper {
  .add-btndis {
    padding-left: 2.5rem;
  }
  .add-apt {
    margin-left: 1.5625rem;
    text-align: center;
    padding-right: 2.5rem;
  }
}
