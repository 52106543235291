@import '../../../style/variables.scss';
@import '../../../style/functions.scss';

.doctor-location-wrapper {
  .doctor-location-container {
    margin-top: 1rem;
    .detailed-card-wrapper {
      background: $default-white-color 0% 0% no-repeat padding-box;
      border-radius: get_8_pixel_box(1.25);
      border: 1px solid $doctor-view-border-color;
      .detailed-card-container {
        padding: 2rem;
        .left-section {
          .hospital-name {
            color: $font-primary-color;
            opacity: 1;
            text-decoration: underline;
            font: normal normal 600 1.2rem/2rem $text-font-regular;
          }
          .address {
            letter-spacing: 0px;
            color: $doctor-title-color;
            opacity: 1;
            font-size: 1.15rem;
            font-family: $text-font-regular;
            line-height: 2rem;
          }
          .bottom-section {
            margin-top: 1.2rem;
            .child-sec {
              width: 25%;
              .child-sec-2 {
                .child-sec-2-margin {
                  margin-top: 0.5rem;
                }
              }
              .images-section {
                display: flex;
                align-items: center;
                img {
                  width: 3.5rem;
                  height: 3.5rem;
                  margin-right: 0.8rem;
                  border-radius: 5px;
                }
              }
            }
          }
        }
        .time-text {
          margin-top: get_8_pixel_box(-1);
        }
        .more-fees-section {
          margin-top: 2px;
          margin-left: 12px;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
