@import '../../../../style/variables.scss';

.hospital-view-wrapper {
  .hospital-view-container {
    .left-section {
      .tabs-section {
        margin-top: 1rem;
        background: $default-white-color 0% 0% no-repeat padding-box;
        border: 1px solid $hospital-view-border-color;
        border-radius: 10px;
        .body-section {
          padding: 2rem;
        }
      }
    }
  }
}
