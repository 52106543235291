@import '../../../../../../../../src/style/variables.scss';
.master-template-dialog-wrapper {
  .template-title-wrapper {
    .template-title {
      font-size: 1.325rem;
      font-weight: 600;
    }
  }

  .css-8b25z8-MuiPaper-root-MuiDialog-paper {
    border-radius: 0.625rem;
    width: 100%;
  }

  .template-main-grid {
    width: 100%;

    margin-bottom: 3.125rem;
    .search-bar-grid {
      margin: 0.5rem 0rem;
      .search-bar-style {
        width: 100%;
        border: 0.063rem solid $other-message-border-color;
        border-radius: 0.375rem;
        height: 2.75rem;
        justify-content: center;
      }
      ::placeholder {
        font-size: 1.05rem;
        color: $or-text-color;
      }

      .search-bar-icon {
        margin-right: 0.625rem;
      }
    }

    .one-template-grid {
      height: 30vh;
      overflow-y: auto;
      overflow-x: hidden;
      .one-template {
        height: 2.6rem;
        align-items: center;
        margin: 0.313rem;
        border-radius: 0.375rem;
        .template-name {
          font-size: 1.08rem !important;
          margin-left: 0.625rem;
          cursor: pointer;
          width: 100% !important;
        }

        .preview-button-grid {
          .preview-button {
            color: $video-status-color;
            background-color: $history-online-status;
            width: fit-content;
            border-radius: 0.375rem;
            font-size: 1rem;
            font-weight: 500;
          }
        }
      }
      .selected {
        color: $app-primary-color !important;
        background-color: $template-selected-background-color;
      }
    }
  }
}
