@import '../../../../../../src/style/variables.scss';

.tabInactive {
  border: 0.0625rem solid $timeline-report-border-color_1;
  border-radius: 0.3125rem;
  background-color: $default-white-color;
  color: $default-black-color;
}

.tabInactive1 {
  // border: 0.0625rem solid $timeline-report-border-color_1;
  border-radius: 0.3125rem;
  background-color: $default-white-color;
  color: $default-black-color;
}

.tabActive {
  background-color: $app-primary-color;
  color: $default-white-color;
}

.filterColors {
  // background-color: nth($colors, random(length($colors)));
  border-radius: 5px;
  color: $default-white-color;
}

.scheduler-wrapper {
  border-radius: 0.625rem;
  background-color: $default-white-color;
  padding: 0.5rem;
  border: 0.0625rem solid $border-color-box-2;
  cursor: pointer;
}
