@import '../../../../style/variables.scss';
@import '../../../../style/functions.scss';

// .confirmation-popup-wrapper {
.confirmation-popup {
  background: $default-white-color 0% 0% no-repeat padding-box !important;
  border-radius: get_8_pixel_box(2.625) !important;
  opacity: 1 !important;
  max-width: 35rem !important;

  .confirmation-popup-content {
    padding: 2rem;
    padding-top: 3rem;
    width: 95%;
    margin: 0 auto;
  }

  .confirmation-popup-actions {
    width: 95%;
    margin: 0 auto;
    justify-content: flex-start;
    align-items: flex-start;

    .yes-button {
      font: normal normal 600 1.2rem/1.5rem $text-font-regular !important;
      height: 3rem;
      border-radius: get_8_pixel_box(0.5) !important;
    }

    .no-button {
      background-color: $default-black-color;
    }
  }
}
