@import '../../../style/variables.scss';
@import '../../../style/functions';

.sub-tabs-wrapper {
  .MuiSvgIcon-fontSizeSmall {
    font-size: 0.75rem !important;
  }
  .MuiTabs-indicator {
    height: 0.15rem;
    border-radius: 2px 2px 0px 0px;
    text-align: center;
    background: $app-primary-color 0% 0% no-repeat padding-box;
  }
  .MuiTab-root {
    min-width: 3rem !important;
    padding: 0;
    margin-left: get_8_pixel_box(2);
    outline: 0;
  }

  .MuiTab-root:first-child {
    margin-left: 0;
  }

  .MuiTab-textColorPrimary {
    color: $font-primary-color !important;
    text-transform: none;
    font-size: 1.2rem;
    font-family: $text-font-regular !important;
    font-weight: normal;
  }

  .MuiBadge-anchorOriginTopRightRectangle {
    position: relative;
    top: 15px;
    right: 15px;
    margin-left: 10px;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: $sub-tabs-selected-link-color !important;
    font-family: $text-font-regular !important;
    font-weight: bold;
    font-size: 1.2rem;
  }

  .MuiBadge-badge {
    font-family: $text-font-regular;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    color: $default-white-color;
    background-color: $default-white-color;
  }
}

.tabs-border-bottom {
  border-bottom: 1px solid $border-primary-color;
  opacity: 1;
  padding-top: 0.5rem !important;
}
