@import '../../../../style/variables.scss';
@import '../../../../style/functions.scss';

.person-relation-wrapper {
  width: 100%;

  .relationship-header-text {
    text-align: left;
    font: normal normal normal 1.2rem/1.4rem $text-font-regular;
    color: $font-primary-color;
    opacity: 1;

    .or-text {
      color: $or-text-color;
    }
  }
}
