@import '../../../style/variables.scss';

.hospital-health-forums-wrapper {
  .hospital-health-forums-container {
    .get-link-label {
      font: normal normal 600 1.3rem/2rem $text-font-regular;
      color: $font-primary-color;
    }

    .send-link-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 3.3rem;
      font: normal normal normal 1.15rem/2rem $text-font-regular;
      width: 100%;
    }

    .hospital-forum-categories-component {
      background: $default-white-color 0% 0% no-repeat padding-box;
      border: 1px solid $white-color-1;
      border-radius: 4px 4px 0 0;
      margin-top: -1.1rem;

      .questions-dropdown-container {
        background: #f8f8f8 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 1rem;
        padding-bottom: 0;
      }
      .questions-card-container {
        padding: 1rem;
        margin-top: 1rem;
      }
      .questions-card-container1 {
        padding: 1rem;
        margin-top: 1rem;
        height: 14.6875rem;
        overflow-y: scroll;
      }
    }
  }
}
