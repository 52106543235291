@import '../../../../../../../../src/style/variables.scss';

.provide-vaccine-dialog-wrapper {
  .provide-vaccine-title-wrapper {
    .provide-vaccine-title {
      text-transform: capitalize;
      font-size: 1.3rem;
      font-weight: 600;
    }

    .close-icon {
      display: flex;
      justify-content: flex-end;
    }
  }

  .provide-vaccine-content-wrapper {
    .provide-vaccine-one {
      display: flex;
      border: 0.063rem solid $border-color-box-2;
      border-radius: 0.375rem;
      padding: 0.625rem;
      margin: 0.438rem 0rem;

      .provide-vaccine-data-grid {
        .provide-vaccine-data-title {
          font-size: 1.2rem;
          font-weight: 600;
          margin: 0.188rem 0rem;
        }

        .provide-vaccine-data-text {
          font-size: 0.9rem;
          color: $or-text-color;
          margin: 0.188rem 0rem;
        }

        .provide-vaccine-input-main-grid {
          .provide-vaccine-input-grid {
            margin: 0.438rem 0.625rem 0.438rem 0rem;

            .provide-vaccine-text-field {
              border: 0.063rem solid $border-color-box;
              height: 1.938rem;
              justify-content: center;
              padding-left: 0.313rem;
              border-radius: 0.375rem;
            }
            ::placeholder {
              font-size: 0.85rem;
            }
          }
          .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
            padding: 0rem 1rem !important;
          }
        }

        .upload-button-grid {
          margin: 0.313rem 0rem;
          .upload-button {
            width: 100%;
            border: 0.063rem solid $app-primary-color;
            border-radius: 0.375rem;
            background-color: $event-upload-button;
          }
        }
      }
    }

    .single-pdf-attachment {
      margin: 0.5rem 0.5rem;
      border-radius: 0.725rem;
      display: flex;
      align-items: center;
      height: 5rem;
      width: 100%;
      justify-content: space-between;

      .single-pdf-icon {
        .css-i4bv87-MuiSvgIcon-root {
          height: 2.5rem !important;
          width: 2.5rem !important;
        }
      }

      .single-pdf-icon-and-name {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .single-pdf-file-name {
        font-size: 1.2rem;
        font-weight: 600;
        margin-left: 0.3rem;
        justify-content: left !important;
      }
    }
  }

  .provide-vaccine-divider {
    margin: 0.5rem 0rem;
  }

  .css-1uaxfgw-MuiButtonBase-root-MuiIconButton-root {
    padding: 0rem !important;
  }

  .css-2foxuz-MuiPaper-root-MuiDialog-paper {
    border-radius: 0.625rem;
  }

  .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0rem !important;
  }

  .css-1rih5rx-MuiButtonBase-root-MuiIconButton-root {
    padding: 0.375rem !important;
  }
}
