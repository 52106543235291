.pdf-dialog-wrapper {
  .pdf-title-wrapper {
    .pdf-title {
      font-size: 1.3rem;
      font-weight: 600;
      padding: 0.625rem;
    }

    .close-icon {
      display: flex;
      justify-content: flex-end;
    }
  }

  .css-m1b0vd-MuiDialogTitle-root {
    padding: 0.313rem 0.625rem !important;
  }

  .css-1y6f8wu-MuiDialogContent-root {
    overflow-y: hidden;
    height: 90vh;
  }
}
