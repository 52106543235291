@import '../../../../style/variables.scss';

.new-person-registration-popup {
  background: $default-white-color 0% 0% no-repeat padding-box !important;
  border-radius: 0.4rem !important;
  opacity: 1 !important;
  max-width: 45rem !important;
  max-height: calc(100% - 0.5rem) !important;
  padding: 0 1rem;

  .popup-close-icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .new-person-registration-content {
    .popup-title {
      font-weight: 600;
      font-size: 1.25rem;
      color: $font-secondary-color;
      line-height: 1.75rem;
    }
    .popup-sub-title {
      font-weight: 400;
      font-size: 1rem;
      color: $or-text-color;
      line-height: 1.25rem;
    }

    .field-label-text {
      font-size: 1rem;
      color: $default-black-color;
      line-height: 1.25rem;
    }

    .MuiFormControlLabel-label {
      font-size: 1rem;
      color: $font-secondary-color;
    }
  }
  .new-person-registration-actions {
    .new-person-save-button {
      width: 100%;
      font: normal normal 600 1.2rem/1.5rem $text-font-regular !important;
      height: 3rem;
      border-radius: 0.4rem;
      color: $default-white-color;
    }
  }
}
