@import '../../../../../../style/variables.scss';

.patient-details-wrapper {
  border-radius: 0.75rem;
  border: 0.0625rem solid $border-color-box-2;
  background: $default-white-color;

  .profile-icon-grid {
    margin-right: 2.5rem;
    .profile-icon {
      width: 5.75rem;
      height: 5.75rem;
    }
  }

  .name-details {
    padding-bottom: 0.625rem;
  }

  .name {
    .text {
      font-size: 1.375rem;
      font-weight: 600;
      padding-right: 0.625rem;
      text-transform: capitalize;
      font-style: normal;
    }

    .status-type {
      color: $video-status-color;
      height: 1.125rem;
      display: flex;
      justify-content: flex-start;
      margin-left: 0.3125rem;
      background-color: $history-online-status;
    }

    .white {
      color: $default-white-color;
    }

    .category {
      color: $or-text-color;
      height: 1.875rem;
      margin-left: 0.3125rem;
      margin-top: 0.3125rem;
      font-size: 1rem;
      text-transform: capitalize;
      display: flex;
      justify-content: space-between;
    }

    .doctor-phone-no {
      font-size: 1.1rem;
    }

    .email-gender-phone {
      margin-top: 0.1875rem;

      .person-email {
        font-size: 1rem;
        color: $font-secondary-color;
      }

      .age {
        text-transform: capitalize;
        color: $font-secondary-color;
      }
      .phone-number {
        color: $font-secondary-color;
      }
    }
  }

  .divider {
    width: 100%;
    height: 0.0625rem;
  }

  .chief {
    border-radius: 0.625rem;
    padding: 0.5rem 0.625rem;
  }
  .complaint {
    align-self: center;
    font-size: 0.875rem;
    font-weight: 400;
    color: $or-text-color;
    margin-top: 0.25rem;
    padding: 0.5rem 0rem;
  }
  .complaintText {
    font-size: 1.15rem;
    font-weight: 400;
    color: $font-secondary-color;
    text-transform: capitalize;
    margin-top: 0.25rem;
  }

  .patient-history-connect-now-grid {
    margin-top: 0rem;
  }

  .patientHistory {
    width: 100%;
    height: 3.1rem;
    border-radius: 0.4375rem !important;
    font-size: 1rem;
    margin-top: 0.5rem;
  }

  .history-button {
    border: 0.0625rem solid $history-button-border-color;
    box-sizing: border-box;
    box-shadow: 0rem 0.1875rem 0.1875rem $history-box-shadow-color !important;
    font-size: 1rem;
  }

  .align-button-center {
    justify-content: center;
  }

  .enter-vitals-button {
    border: 0.0625rem solid $app-primary-color;
    background-color: $event-upload-button;
    font-size: 1rem;
  }

  .doctor-name {
    font-size: 1.15rem;
    line-height: 1.75rem;
    color: $virtual-clinic-text;
  }

  .sub-title {
    font-size: 0.9rem;
    line-height: 1.5rem;
    color: $virtual-clinic-subtitle-text;
  }

  .doctor-buttons-container {
    background: $virtual-clinic-bg-color;
    border-radius: 0 0 0.75rem 0.75rem;
  }

  .vitals-button {
    background: $default-white-color;
    border-radius: 0.4375rem !important;
    border: 1px solid $app-primary-color;
    box-sizing: border-box;
    height: 2.25rem;
    margin: 0.5rem 0;
  }

  .vc-button {
    height: 2.25rem;
    border-radius: 0.4375rem !important;
    font-size: 1rem;
    font-weight: 600;
    margin: 0.5rem;
  }

  .vc-profile-icon {
    height: 3.5rem;
    width: 3.5rem;
  }
  .icon-image {
    width: 1.4rem;
  }
  .complaint-grid-buttons {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  // doctor details - Person web visit history

  .branch-name-grid {
    font-size: 1.1rem;
    font-weight: 600;
  }
}
