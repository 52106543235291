@import '../../../../style/variables.scss';

.new-person-vitals-popup {
  background: $default-white-color 0% 0% no-repeat padding-box !important;
  border-radius: 0.7rem !important;
  opacity: 1 !important;
  max-width: 30rem !important;
  max-height: calc(100% - 0.5rem) !important;

  .popup-title {
    font-weight: 600;
    font-size: 1.25rem;
    color: $default-black-color;
  }

  .header-container {
    background: $grey-color-5;
    display: flex;
    padding: 0.5rem 1rem;
    .header-text {
      font-weight: 600;
      font-size: 1rem;
      color: $font-secondary-color;
    }
  }

  .vitals-container {
    background: $default-white-color;
    border: 1px solid $border-color-box-2;
    box-sizing: border-box;
    padding: 0.5rem 1rem;

    .vital-name {
      font-weight: 300;
      font-size: 1.1rem;
      line-height: 1.4rem;
      color: $font-secondary-color;
    }
  }

  .divider {
    margin: 1rem 0;
    opacity: 0.2;
    border: 1px solid $or-text-color;
  }

  .appointment-cancel-button {
    height: 3rem;
    border-radius: 0.4rem;
    background: $vc-cancel-button-bg-color;
    width: 100%;
    font-weight: 600;
    font-size: 1.15rem;
    color: $app-primary-color;
  }

  .vital-text-field-common-grid {
    padding-left: 1rem;
  }

  .header-text-values {
    padding-left: 1rem;
  }

  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0.2rem 0.5rem !important;
  }
}
