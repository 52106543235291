@import '../../../../src/style/variables.scss';

.upload-attachment-dialog-wrapper {
  background: $default-white-color 0% 0% no-repeat padding-box !important;
  border-radius: 0.75rem !important;
  opacity: 1 !important;
  max-width: 38rem !important;
  max-height: calc(100% - 0.5rem) !important;

  .upload-attachment-title {
    font-size: 1.15rem;
    font-weight: 600;
    color: $font-secondary-color;
  }

  .upload-attachment-content-grid {
    background: $add-subjective-title-background-color;
    border: 1px dashed $border-color-box;
    border-radius: 1.15rem;
    padding: 1.25rem;

    .name-text {
      color: $or-text-color;
      font-weight: 600;
      font-size: 1.15rem;
    }

    .file-name-text-filed {
      margin-top: 0.75rem;
    }

    .files-container {
      background: $default-white-color;
      border: 1px solid $border-color-box-2;
      border-radius: 0.45rem;
      margin-top: 1rem;
      padding: 0.75rem;

      .browse-file-name {
        color: $or-text-color;
        font-weight: 600;
        font-size: 1.05rem;
      }

      .browse-button {
        background: $button-background-color-2;
        border-radius: 0.45rem;
        font-weight: 600;
        font-size: 0.85rem;
        color: $default-black-color;
        width: 100%;
        height: 1.5rem;
        text-transform: capitalize;
      }
    }

    .choose-file-input-box {
      display: none;
    }
  }

  .file-name-and-details {
    align-items: center;
    margin: 0.313rem;
    padding: 0.313rem;
    padding-right: 0rem !important;
    border-radius: 0.625rem;

    border: 0.063rem solid $border-color-box-2;
    .file-name {
      font-size: 1rem;
    }
  }

  .submit-button {
    border-radius: 0.45rem !important;
  }
}
