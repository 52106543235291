@import '../../../../src/style/variables.scss';

.rpv-core__display--block-medium {
  display: none;
}
.rpv-default-layout__sidebar--ltr {
  display: none;
}

.rpv-toolbar__right {
  display: none;
}

.rpv-toolbar__left {
  display: none;
}

.rpv-toolbar__center {
  display: none;
}

.rpv-default-layout__toolbar {
  display: none;
}
