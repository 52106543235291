@import '../../../../style/variables.scss';

.new-appointment-bookings-popup {
  background: $default-white-color 0% 0% no-repeat padding-box !important;
  border-radius: 0.7rem !important;
  opacity: 1 !important;
  max-width: 80rem !important;
  max-height: calc(100% - 0.5rem) !important;

  .new-appointment-bookings-content {
    .popup-title {
      font-weight: 300;
      font-size: 1.4rem;
      color: $font-secondary-color;
    }

    .close-icon {
      position: absolute;
      top: 1%;
      right: 1%;
    }

    .doctor-patient-title {
      text-transform: capitalize;
      font-weight: 600;
      font-size: 1.15rem;
      color: $virtual-clinic-doctor-patient-title-color;
      padding: 1rem 0;
    }

    .patient-info-text {
      font-style: normal;
      font-weight: 600;
      font-size: 1.15rem;
      line-height: 1.5rem;
      color: $vc-cancel-info-text-color;
      margin-top: 2rem;

      .patient-link-text {
        color: $app-primary-color;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .patient-card-container {
      background: $virtual-clinic-doctor-patient-bg-color;
      border-radius: 0.75rem;
      padding: 1rem;
    }

    .selected-card-container {
      background: $patient-card-background-color;
      border: 1px solid $app-primary-color;
      box-sizing: border-box;
    }

    .patient-name {
      font-weight: 600;
      font-size: 1.15rem;
      color: $font-secondary-color;
    }

    .father-name {
      font-weight: 600;
      font-size: 0.75rem;
      color: $or-text-color;
    }

    .card-value {
      font-weight: 400;
      font-size: 1.1rem;
      color: $or-text-color;
    }

    .branch-name {
      font-weight: 600;
      font-size: 1.25rem;
      color: $virtual-clinic-doctor-patient-title-color;
      line-height: 1.75rem;
    }

    .days-timings {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.15rem;
      color: $or-text-color;
    }

    .book-button {
      height: 1.7rem;
      background: $app-primary-color;
      width: 100%;
      font-weight: 600;
      font-size: 1rem;
      color: $default-white-color;
      border-radius: 0.425rem;
    }

    .appointment-button {
      margin-top: 0.5rem;
      width: 100%;
      background: $default-white-color;
      border: 1px solid $app-primary-color;
      box-sizing: border-box;
      border-radius: 0.425rem;
      font-weight: 600;
      font-size: 0.7rem;
      color: $app-primary-color;
      padding: 0 0.5rem !important;
    }

    .continue-button {
      height: 3.25rem;
      background: $app-primary-color;
      border-radius: 0.425rem;
      width: 100%;
      font-size: 1.25rem;
      color: $default-white-color;
      margin-top: 1rem;
    }

    .disabled-button {
      opacity: 0.6;
    }

    .date-appointment-container {
      padding: 1rem 0;

      .field-label-text {
        font-size: 1rem;
        color: $default-black-color;
        line-height: 1.25rem;
        margin-top: 1rem;
      }
    }

    .date-picker-container {
      margin-top: 1rem;
      background: $default-white-color;
      border: 0.846673px solid $medicine-button-border-color;
      box-sizing: border-box;
      // border-radius: 0.75rem;

      .date-picker-label {
        padding: 1rem;
        font-weight: 600;
        font-size: 1rem;
        color: $default-black-color;
      }
    }

    .slots-container {
      margin-top: 1rem;

      .slots-text {
        font-weight: 600;
        font-size: 1.15rem;
        color: $default-black-color;
      }

      .divider {
        margin: 1rem 0rem 0rem 0rem;
        opacity: 0.2;
        border: 1px solid $default-black-color;
      }

      .sessions-container {
        padding: 1rem 0;

        .session-name-text {
          font-weight: 400;
          font-size: 1.25rem;
          color: $font-secondary-color;
        }

        .slot-button {
          font-size: 0.85rem;
          font-weight: 400;
          color: $font-secondary-color;
          border: 1px solid $slot-button-border-color;
        }

        .border-button {
          border: 1px solid $app-primary-color;
          background-color: $app-primary-color;
          color: $default-white-color;
        }

        .expanded-button {
          background: none;
          border: 0;
          color: $sub-tabs-selected-link-color;
          cursor: pointer;
          padding: 0;
          font: normal normal bold 1rem/1.5rem $text-font-regular;
          letter-spacing: 0.013rem;
          padding-left: 0.5rem;
        }

        .not-available-text {
          font-family: $text-font-regular;
          font-size: 1.15rem;
          letter-spacing: 0.27px;
          color: $font-secondary-color;
          opacity: 1;
          margin-top: 0.5rem;
        }
      }
    }
  }

  .search-and-drop-down-grid {
    display: flex;
    align-items: center;
  }

  .search-and-drop-down-grid-search {
    width: 60% !important;
  }

  .doctor-list-for-specialization-main-grid {
    height: 20rem !important;
    overflow-y: scroll;

    .doctor-list-for-specialization-grid {
      border: 0.063rem solid $capture-vitals-border-values-vitals;
      border-radius: 0.375rem;
      padding: 1rem;
      margin-top: 0.5rem;

      .doctor-list-for-specialization-single-card {
        text-transform: capitalize;
        display: flex;
      }

      .doctor-details-info {
        text-transform: capitalize;

        .doctor-details-info-specialization {
          padding: 0.5rem 0rem;
        }
      }
    }
    :hover {
      cursor: pointer;
    }
  }
}
