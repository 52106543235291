@import '../../../../../../style/variables.scss';

.calendar-wrapper {
  background: $default-white-color;
  border-radius: 0.75rem;
  padding-top: 0.313rem;
  .transitionDiv {
    margin-top: 0;
    padding: 0 1rem;
  }

  .hideIcon {
    justify-content: center;
    display: flex;
    align-items: center;
    padding-top: 0.125rem !important;

    .icon {
      border: 0.0625rem solid $grey-color-5;
      box-sizing: border-box;
      box-shadow: 0rem 0.25rem 0.25rem rgba(145, 131, 131, 0.25);
      border-radius: 1rem;

      .show-hide-icon {
        transform: rotate(180deg);
      }
    }
  }
  @keyframes inAnimation {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes outAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
      transform: translateY(100%);
      transition-delay: calc(0.025s * (var(--length) - (var(--index) + 1)));
      transition-duration: 0.5s;
    }
  }
}
