@import '../../../../../../../style/variables.scss';

.write-message-wrapper {
  .write-message-title {
    font-size: 1.125rem;
    color: $font-secondary-color;
  }

  .write-message-content {
    display: flex;
    flex-direction: column;
  }

  .message-radio-group {
    .one-message-radio-button {
      width: 100%;

      .one-message-radio-text {
        font-size: 1rem;
      }
    }
  }

  .other-message-text-box {
    border: 0.0625rem solid $other-message-border-color;
    width: 80%;
    margin: 0.625rem;
    border-radius: 0.625rem;
  }

  .send-message-button-grid {
    .send-go-back-button {
      width: 100%;
      margin: 0.5rem 0rem;
    }

    .send-cancel-button {
      width: 100%;
      margin: 0.5rem 0rem;
      background-color: $prescription-background-color;
      color: $prescription-color;
    }
  }
}
