@import '../../../style/variables.scss';

.multiple-images-view-wrapper {
  .multiple-images-view-container {
    .images-section {
      display: flex;
      align-items: center;
      img {
        width: 3.5rem;
        height: 3.5rem;
        margin-right: 0.8rem;
        border-radius: 5px;
      }
    }
  }
}