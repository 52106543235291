@import '../../../style/variables.scss';

.left-side-illustration-wrapper {
  padding: 1.5rem 2.5rem !important;
  height: 100%;

  .left-side-illustration-title {
    font: normal normal bold 1.25rem/2rem $text-font-regular;
    color: $default-white-color;
  }

  .left-side-speciality-text {
    font: normal normal 600 1rem/1.5rem $text-font-regular;
    color: $default-white-color;
  }

  .left-side-illustration-footer-text {
    font: normal normal 600 1rem/1.5rem $text-font-regular;
    letter-spacing: 0.2px;
    color: #93e6ff;
  }

  .avatar-styles {
    background: $default-white-color 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px $box-shadow-color;
    border-radius: 15px;
    height: 3.35rem;
    width: 3.35rem;
  }
}
