@import './../../../../../../../style/variables.scss';

.medicine-taken-heading-title {
  .medicine-taken-title {
    font-size: 1.2rem;
    font-weight: 600;
  }
}
.time-selector-wrapper {
  font-size: 1rem;
  font-weight: 600;
  .time-selector-main-grid {
    border: 0.065rem solid $capture-vitals-visit-id-border;
    width: fit-content;
    margin: 1rem;
    border-radius: 0.5rem;
    background-color: $default-logo-color;
  }

  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    width: 5rem !important;
  }

  .css-1etscvx-MuiFormHelperText-root {
    display: none;
  }

  .select-time-heading {
    padding: 0.5rem;
  }
}
.medicine-taken-button {
  font-size: 1rem !important;
}
