@import '../../../../style/variables.scss';
@import '../../../../style/functions.scss';

.registration-user-popup {
  background: $default-white-color 0% 0% no-repeat padding-box !important;
  border-radius: get_8_pixel_box(2.625) !important;
  opacity: 1 !important;
  max-width: 80rem !important;
  max-height: calc(100% - 0.5rem) !important;

  .popup-left-section {
    background: $app-primary-color 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .user-registration-title {
    background: $app-primary-color 0% 0% no-repeat padding-box;
    padding: 1.5rem 2rem;
  }

  .user-registration-content {
    padding: 1.5rem 2rem;
    width: 90%;
    margin: 0 auto;

    .information-text {
      font: normal normal normal 1rem/1.5rem $text-font-regular;
    }

    .helper-text {
      font: normal normal normal 0.9rem/1.5rem $text-font-regular;
    }

    .login-button-container {
      background: $background-other-color 0% 0% no-repeat padding-box;
      border: 1px solid $border-other-color;
      border-radius: get_8_pixel_box(0.375);
      font: normal normal 600 1rem/1.8rem $text-font-regular;
      color: $app-primary-color;
      width: 7.25rem;
    }

    .password-info-text {
      font: normal normal normal 0.8rem/1.1rem $text-font-regular;
      color: $grey-color-3;
    }

    .done-icon-pwd-length,
    .done-pwd-req-icon {
      color: $border-tertiary-color;
    }

    .done-pwd-length-success-icon,
    .done-pwd-req-success-icon {
      color: $app-success-color;
    }
  }

  .user-registration-actions {
    padding: 1.5rem 2rem;
    width: 95%;
    margin: 0 auto;
    justify-content: flex-start;
    align-items: flex-start;

    .permissions-text {
      font: normal normal normal 0.9rem/1.2rem $text-font-regular;
    }

    .terms-conditions-text {
      margin-left: 0.5rem;
      font: normal normal 600 1rem/1.2rem $text-font-regular;
      color: $app-primary-color;
    }

    .create-account-button {
      width: 100%;
      font: normal normal 600 1.2rem/1.5rem $text-font-regular;
      border-radius: get_8_pixel_box(0.75);
      height: 3.5rem;
      margin-top: 1rem;
    }

    .skip-link {
      font: normal normal normal 1.1rem/1.5rem $text-font-regular;
      color: $font-primary-color;
      margin-top: 1rem;
    }
  }
}
