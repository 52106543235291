@import '../../../style/variables.scss';
@import '../../../style/functions.scss';

.view-more-button {
  font-size: 1.2rem !important;
  text-decoration: underline !important;
  margin-left: -1rem !important;
  background-color: transparent;
}

.view-more-button:focus,
.view-more-button:hover {
  background-color: transparent !important;
}

.MuiAutocomplete-paper {
  border-radius: get_8_pixel_box(0.75);
  box-shadow: 0 0.3125rem 0.3125rem -0.1875rem rgba(0, 0, 0, 0.2),
    0 0.5rem 0.625rem 0.0625rem rgba(0, 0, 0, 0.14),
    0 0.1875rem 0.875rem 0.125rem rgba(0, 0, 0, 0.12) !important;
  font-size: 1.4rem !important;
}
.MuiAutocomplete-listbox {
  padding: get_8_pixel_box(1) !important;
  max-height: none !important;
}

.new-patient-appointment-search-option {
  font-size: 1.05rem !important;
}

.listbox-scroll-style {
  height: 30vh !important;
  overflow-y: scroll !important;
}

.imaging-search-bar {
  height: 30vh !important;
  overflow-y: scroll !important;
}

.medicine-search-bar {
  height: 30vh !important;
  overflow-y: scroll !important;
}

.width-reduced {
  width: 100% !important;
}
