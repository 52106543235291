.vaccine-updated-dialog-wrapper {
  .vaccine-updated-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .vaccine-data-updated {
      font-size: 1rem;
      font-weight: 600;
    }

    .vaccine-updated-tick-icon-grid {
      display: flex;
      margin-bottom: 0.625rem;
      width: 100%;
      justify-content: center;
    }

    .vaccine-data-updated-text-grid {
      padding: 1rem 0rem;
      text-align: center;
    }
  }

  .css-2foxuz-MuiPaper-root-MuiDialog-paper {
    border-radius: 0.625rem;
  }

  .css-m1b0vd-MuiDialogTitle-root {
    height: 0.188rem;
  }
}
