@import '../../../../../../../../style/variables.scss';

.show-attachments-wrapper {
  .popup-border {
    .add-medicine {
      font-size: 1.25rem;
      font-weight: 300;
    }
    .close-icon {
      margin-top: 0.188rem;
    }
  }
  .css-15fev7r-MuiGrid-root {
    align-items: center;
  }

  .single-attachment {
    border: 0.063rem solid $border-color-box-2;
    border-radius: 10px;
    height: 3rem;
    margin: 0.5rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .single-attachment-name {
      cursor: pointer;
    }
  }
}
