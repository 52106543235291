@import './../../../../../../../style/variables.scss';

.capture-vitals-main-wrapper {
  padding: 1rem;

  .capture-vitals-grid {
    border: 0.065rem solid $border-color-box-2;
    border-radius: 0.375rem;

    .capture-vitals-title-grid {
      padding: 0.5rem;
      border-bottom: 0.065rem solid $border-color-box-2;
      background-color: $default-logo-color;
      display: flex;
      align-items: center;

      .capture-vitals-title {
        font-size: 1rem;
        font-weight: 600;
      }

      .capture-vitals-visit-id-grid {
        .capture-vitals-visit-id {
          display: flex;
          border: 0.065rem solid $capture-vitals-visit-id-border;
          padding: 0.3rem;
          border-radius: 0.25rem;
        }
        .capture-vitals-visit-id-text {
          font-size: 1rem;
          font-weight: 600;
          padding-right: 0.2rem;
        }
      }
    }

    .capture-vital-info-grid {
      padding: 0.5rem 0.5rem;
      border: 0.063rem solid $capture-vitals-info-border-color;
      margin-right: 0.5rem;

      background-color: $capture-vitals-info-background-color;
      border-top: none;
      border-bottom: none;
      border-radius: 0.25rem;
      font-weight: 600;
      font-size: 1rem;
      overflow-wrap: anywhere;
    }

    .is-array-heading-label {
      background-color: $capture-vitals-array-label;
    }
    .is-array-grid {
      display: flex;
      height: auto;
    }

    .inside-blood-pressure-label {
      padding-top: 1rem;
    }

    .capture-vitals-label-grid {
      margin-bottom: 1rem;
      margin-left: 1rem;
      .labels-main-grid {
        padding-left: 0.5rem;

        .labels-empty-grid {
          height: 8rem;
        }
      }

      .labels-single-label {
        height: 9.1rem;
        border: 0.065rem solid $capture-vitals-info-border-color;
        border-bottom: none;
        border-top: none;
        padding: 0.5rem;
        background-color: $capture-vitals-info-background-color;
        border-radius: 0.25rem;
      }
    }

    .capture-vitals-values-grid {
      margin: 0rem 0.2rem;
    }

    .label-values-main-grid {
      display: flex;
      overflow: hidden;

      .values-main-grid {
        margin-left: 0.3rem;

        .labels-empty-grid {
          height: 8rem;
          display: flex;
          align-items: center;
        }

        .labels-single-label {
          border: 0.065rem solid $capture-vitals-border-values-vitals;
          border-bottom: none;
          border-top: none;
          padding: 0.5rem;
          background-color: $patient-card-background-color;
          border-radius: 0.25rem;
          height: 2.1rem;
        }
      }
      .capture-vitals-values-main-grid {
        overflow-x: auto;
        flex-wrap: nowrap;
      }
    }
  }

  .blood-pressure-text {
    margin-bottom: 1rem;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .labels-answers-grid {
    text-align: center;
    height: 27.59rem;
    background-color: $patient-card-background-color;
  }

  .labels-date-grid {
    transform: rotateZ(-90deg);
    background-color: $patient-card-background-color;
    padding: 0.5rem 0rem;
    width: max-content;
  }

  .capture-vitals-comments-main-grid {
    padding: 1rem;

    .capture-vital-single-comment-grid {
      padding: 1rem;
      margin: 1rem 0rem;
      border: 0.065rem solid $capture-vitals-border-values-vitals;
      background-color: $capture-vitals-info-background-color;
      border-radius: 0.25rem;
      display: flex;
      justify-content: space-between;

      .vital-comment-date-grid {
        text-align: end;
      }
    }
  }

  .dialog-action-button-grid-capture-vitals {
    padding: 0rem 1rem 1rem 1rem;
    font-size: 1.1rem;
    .capture-vitals-add-vitals {
      cursor: pointer;
    }
  }
  .blood-pressure-text-empty-field {
    height: 3rem !important;
  }

  .give-height-to-blood-pressure {
    height: 4rem !important;
    margin-top: 2.7rem;
  }
}
