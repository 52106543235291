@import '../../../../../../src/style/variables.scss';

.schedular-wrapper {
  box-shadow: none;
  width: 43.75rem;

  tr:first-of-type .Cell-horizontalCell {
    border-top: none;
    background-color: $scheduler-title-color;
    height: 2rem;
    text-align: center;
    margin-top: 1.25rem;
  }

  .Cell-text {
    font-weight: $app-tertiary-font-weight_1 !important;
    font-size: $text-tertiary-font-size_4 !important;
    color: $font-secondary-color !important;
    text-transform: capitalize;
    text-align: center;
    width: 100%;
    margin-bottom: 0.9375rem;
  }

  .MainLayout-header {
    margin-bottom: 0.9375rem;
  }

  .MainLayout-background {
    background-color: $scheduler-title-color !important;
    // height: 5rem;
    text-align: center;
  }

  .Cell-dayView {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    font-weight: $app-tertiary-font-weight_1 !important;
    font-size: $text-tertiary-font-size_4 !important;
    color: $font-secondary-color !important;
  }
  .Cell-dayOfWeek {
    color: $font-secondary-color !important;
    align-self: center;
    font-weight: $app-tertiary-font-weight_1 !important;
    font-size: $text-tertiary-font-size_4 !important;
    color: $font-secondary-color !important;
  }
  .Cell-dayOfMonth {
    color: $font-secondary-color !important;
    padding: 0.3125rem !important;
    text-transform: capitalize;
    font-weight: $app-tertiary-font-weight_1 !important;
    font-size: $text-tertiary-font-size_4 !important;
    color: $font-secondary-color !important;
  }

  .cell {
    border: 0.0625rem solid $timeline-report-border-color_1 !important;
    background-color: $default-white-color;
    cursor: pointer;
    // height: 2.3rem !important;
  }

  .cell-disable {
    border: 0.0625rem solid $timeline-report-border-color_1 !important;
    background-color: $add-subjective-title-background-color;
    cursor: default;
    // height: 2.3rem !important;
  }

  .day-layout {
    width: 25rem;
  }

  .border-cell {
    border: 0.0625rem solid $timeline-report-border-color_1 !important;
  }

  .appointment-view-card {
    width: 23.875rem;
    // height:382px;
  }

  .line {
    height: '0.125rem';
    width: '100%';
    transform: 'translate(0, -1px)';
  }
  .circle {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    transform: 'translate(-50%, -50%)';
  }
  .nowIndicator {
    position: absolute;
    left: 0;
    top: 10;
    z-index: 1;
  }
}

.monthly-scheduler-wrapper {
  .Cell-dayOfWeek {
    color: $default-black-color !important;
    font-weight: 400 !important;
    font-size: $text-font-size !important;
  }
  .cell {
    border: 0.0625rem solid $timeline-report-border-color_1 !important;
    background-color: $default-white-color;
    cursor: pointer;
    height: 7.3125rem !important;
  }

  .cell-disable {
    background-color: $add-subjective-title-background-color;
    cursor: default;
  }
}
.view-more-popup {
  background: $default-white-color 0% 0% no-repeat padding-box !important;
  border-radius: 0.7rem !important;
  opacity: 1 !important;
  max-width: 45rem !important;
  max-height: calc(100% - 0.5rem) !important;
  .view-more-content {
    .popup-title {
      font-weight: 300;
      font-size: 1.4rem;
      color: $font-secondary-color;
    }
    .close-icon {
      position: absolute;
      top: 1%;
      right: 1%;
    }
  }
  .view-more-grid {
    margin: 0.625rem 0rem;
  }
}
