@import '../../../../style/variables.scss';
@import '../../../../style/functions.scss';

//get_8_pixel_box
.common-dialog-title-wrapper {
  .appointment-header-text {
    text-align: left;
    font: normal normal normal 1.3rem/1.5rem $text-font-regular;
    letter-spacing: 0px;
    color: $default-white-color;
  }
  .doctor-name {
    font: normal normal normal 1.4rem/2rem $text-font-regular;
    color: $default-white-color;
  }
  .appointment-details {
    font: normal normal bold 1rem/1.8rem $text-font-regular;
    color: $default-white-color;
  }
  .address-details {
    font: normal normal normal 1rem/1.8rem $text-font-regular;
    color: $default-white-color;
  }

  .left-side-illustration-footer-text {
    font: normal normal 600 1rem/1.5rem $text-font-regular;
    letter-spacing: 0.2px;
    color: #93e6ff;
  }
}
