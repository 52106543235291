@import '../../../../../../../../src/style/variables.scss';

.nursing-wrapper {
  margin-top: 1rem;
  overflow: hidden;

  height: calc(100vh - 20.25rem);
  .nursing-inside-grid {
    height: calc(100vh - 20.25rem);

    overflow-y: auto;

    .nursing-heading {
      font-size: 1.2rem;
      padding-left: 1rem;
    }
    .nursing-sub-text-grid {
      font-size: 1rem;
      margin-top: 1rem;
      padding: 0.8rem 0rem;
      background-color: $default-white-color;

      .nursing-sub-text {
        font-size: 1rem;
        padding-left: 2rem;
      }
    }

    .nursing-button-main-grid {
      padding: 0.8rem 0rem 0.8rem 2rem;
      background-color: $grey-color-6;

      .nursing-buttons {
        background-color: $white-background-color;
        margin-left: 0.625rem;
        height: fit-content;
        border: 0.063rem solid $other-message-border-color;
        box-sizing: border-box;
        color: $font-secondary-color;
        border-radius: 0.563rem;
        box-shadow: 0rem 0.25rem 0.25rem $box-shadow-color4 !important ;
        font-size: 0.875rem;
        .inside-icon {
          margin-right: 0.313rem;
          color: $video-status-color;
        }
      }
    }

    .sections-main-grid {
      background-color: $white-background-color;
      padding-bottom: 1rem;

      .sections {
        padding: 0 1.25rem;
        margin-top: 0.002rem;
        margin-bottom: 0.5rem;
        .health-box-styles {
          border: 0.063rem solid $border-color-box-2;
          border-radius: 0.625rem;
          width: 100%;
          margin-top: 1rem;
          margin-bottom: 1rem;

          .text-field {
            background-color: $default-logo-color !important;
            padding-top: 0.5rem;
            padding-left: 1rem;
            padding-bottom: 0.5rem;
            display: flex;
            justify-content: space-between;
            font-size: 1rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            height: 2.5rem;

            .test {
              display: inline-flex;

              .text {
                background-color: $default-logo-color;
                padding-top: 0.04rem;
                padding-left: 0.8rem;
                margin-left: -0.7rem;
                font-size: 1rem;
                font-weight: 600;
                text-transform: capitalize;
              }

              .num {
                position: relative;
                padding-left: 0.5rem;
                padding-top: 0.04rem;
              }
            }

            .image-search-icon {
              color: $app-primary-color;
            }

            .inside-box {
              padding: 0 1.5rem;

              .inside-button {
                font-size: 1rem;
              }
            }
          }

          .notes-box {
            border: 0.063rem solid $border-color-box-2;
            border-radius: 0.625rem;
            width: 100%;
            padding-bottom: 0.625rem;

            .text-field {
              background-color: $default-logo-color;
              padding: 0.625rem;
              font-size: 1rem;
              font-weight: 600;
            }

            .inside-box {
              padding: 0.75rem 1.5rem;
              font-size: 0.938rem;
              font-weight: 400;
              height: auto;

              .notes-text {
                margin: 10rem 0rem;
                font-size: 0.938rem;
                font-weight: 400;
                padding-bottom: 1rem;
              }

              .input-field {
                padding: 1.25rem;
                width: 100%;
              }
            }
          }

          .save-button-grid {
            display: flex;
            justify-content: flex-end;
            margin-right: 1.25rem;

            .save-button {
              width: 12.5rem;
              font-size: 1rem;
            }
          }

          .nursing-inside-box {
            margin: 0.625rem;
            border-radius: 0.4375rem;
            justify-content: space-between;

            .link-button {
              cursor: pointer;
            }
          }
        }
      }

      .nursing-action-buttons {
        display: flex;
        justify-content: flex-end;
        padding: 1rem;

        .cancel-button {
          margin: 0.5rem;
          font-size: 1rem;
          width: 8rem;
        }

        .save-button {
          margin: 0.5rem;
          font-size: 1rem;

          width: 8rem;
        }
      }
    }
  }

  .single-attachment-name-and-icon {
    display: flex;
    cursor: pointer;
    align-items: center;
  }

  .single-pdf-attachment {
    border: 0.063rem solid $other-message-border-color;

    margin: 0.5rem 0.5rem;
    border-radius: 0.725rem;
    display: flex;
    align-items: center;

    .single-pdf-file-name {
      margin-left: 0.3rem;
    }
  }
  .add-attachment-button {
    padding: 0.313rem 0.313rem;
    margin: 0.5rem 0rem;
    font-size: 1rem;
  }
  .add-attachment-button-link {
    cursor: pointer;
    margin: 0.5rem 0.8rem !important;
    font-size: 1rem;
  }

  .morning-remainders-main-grid {
    .reminder-interval-text {
      font-size: 1.2rem;
      font-weight: 600;
    }

    .single-medicine-reminder-main-grid {
      width: 100%;
      .single-medicine-reminder-grid {
        padding: 1rem;
      }
      .single-medicine-reminder {
        border: 0.063rem solid $other-message-border-color;
        padding: 1rem;
        border-radius: 0.563rem;

        .single-reminder-name-time-main-grid {
          .medicine-name-grid {
            font-size: 1rem;
            text-transform: capitalize;
            margin-bottom: 0.2rem;
          }

          .medicine-reminder-time-grid {
            display: flex;
            .medicine-reminder-time-text {
              font: 0.5rem;
              color: $or-text-color;
            }
            .medicine-reminder-taken-time-text {
              font: 0.5rem;
              color: $or-text-color;
              font-weight: 600;
              padding-left: 1rem;
            }
          }
        }
      }
    }

    .reminder-completed-single-grid {
      border: 0.063rem solid $green-color-1 !important;
      background-color: $medicine-reminder-background;
    }

    .taken-status-green {
      border: 0.063rem solid $green-color-1 !important;
      background-color: $medicine-reminder-background;
    }

    .taken-status-amber {
      border: 0.063rem solid $medium-bmi-color !important;
      background-color: $medicine-reminder-amber-background !important;
    }

    .taken-status-red {
      border: 0.063rem solid $app-error-color !important;
      background-color: $deleted-medicine-background-color;
    }

    .reminder-completed-text {
      text-decoration: line-through;
      text-decoration-color: $green-color-1;
      color: $green-color-1;
      font-weight: 600;
    }

    .taken-status-green-text {
      text-decoration: line-through;
      text-decoration-color: $green-color-1;
      color: $green-color-1;
      font-weight: 600;
    }

    .taken-status-amber-text {
      text-decoration: line-through;
      text-decoration-color: $medium-bmi-color;
      color: $medium-bmi-color;
      font-weight: 600;
    }

    .taken-status-red-text {
      text-decoration: line-through;
      text-decoration-color: $app-error-color;
      color: $app-error-color;
      font-weight: 600;
    }
  }

  .medicine-reminder-function-main-grid {
    padding: 1rem;
  }

  .calendar-main-grid {
    justify-content: space-around;

    .calendar-day-text {
      padding: 1rem;
      font-size: 1.1rem;
      cursor: pointer;
      color: $or-text-color;
    }
    :hover {
      text-decoration: underline;
    }

    .selected-date {
      text-decoration: underline;
      font-weight: 600;
      font-size: 1.1rem;
      text-underline-offset: 1rem;
      color: $default-black-color;
    }
  }
}
