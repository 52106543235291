body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  font-family: 'Nunito Sans', 'PT Serif', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div,
h1,
h2,
h3,
a,
p,
h4,
h5,
h6,
ul,
li {
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
.app-loader {
  width: 50%;
  height: 50%;
}
.flex-centering {
  display: flex;
  flex-direction: column;
  height: inherit;
  align-items: center;
  justify-content: center;
}

.center-content {
  justify-content: center;
}

.no-padding {
  padding: 0 !important;
}
.no-padding-horizontal {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

/*
* Mui form global css starts here
 */

.MuiFormHelperText-root {
  position: absolute;
  bottom: 0;
}

button.MuiLink-root {
  &:focus {
    outline: 0;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
