@import '../../../style/variables.scss';
@import '../../../style/functions.scss';

.appointment-wrapper {
  background: $default-white-color 0% 0% no-repeat padding-box;
  box-shadow: 0 0 get_8_pixel_box(0.75) $box-shadow-color;
  border: 1px solid $doctor-view-border-color;
  opacity: 1;
  .appointment-container {
    padding: 1rem;
    .title-text {
      text-align: left;
      font: normal normal normal 1.2rem/1.5rem $text-font-regular;
      color: $font-primary-color;
    }
    .fee-dropdown-container {
      margin-top: 1rem;
    }
    .booking-container {
      background: $background-tertiary-color 0% 0% no-repeat padding-box;
      border-radius: get_8_pixel_box(0.5);
      opacity: 1;
      padding: 0.65rem;
      margin-top: get_8_pixel_box(2);
      .row-container {
        display: flex;
        flex-direction: row;
        .booking-label {
          font: normal normal normal 1rem/1.4rem $text-font-regular;
          letter-spacing: 0;
          color: $doctor-view-title-color;
          opacity: 1;
        }
        .option-change-text {
          font-family: $text-font-regular;
          font-size: 1rem;
          letter-spacing: 0;
          color: $app-primary-color;
          opacity: 1;
        }
        .change-clinic-button {
          background: $background-primary-color 0% 0% no-repeat padding-box;
          border-radius: get_8_pixel_box(0.5);
          opacity: 1;
          text-align: left;
          font: normal normal normal 1rem/1.5rem $text-font-regular;
          letter-spacing: 0;
          color: $font-primary-color;
          text-transform: capitalize;
          margin-left: auto;
          padding-bottom: get_8_pixel_box(0.5);
        }
      }
      .hospital-name {
        text-align: left;
        font: normal normal 600 1.1rem/2.3rem $text-font-regular;
        letter-spacing: 0;
        color: $font-primary-color;
        opacity: 1;
      }
      .address-text {
        text-align: left;
        font: normal normal normal 1.1rem/2.2rem $text-font-regular;
        color: $font-primary-color;
        opacity: 1;
      }
    }
    .date-picker-container {
      .time-label {
        font-family: $text-font-regular;
        font-size: 1.2rem;
        letter-spacing: 0;
        color: $font-primary-color;
        opacity: 1;
      }
    }
    .slot-date-container {
      display: flex;
      margin-top: 0.5rem;
      .arrow-container {
        display: flex;
        align-items: center;
        width: 10%;
      }
      .slot-date-buttons-container {
        display: flex;
        overflow-x: hidden;
        margin: 0 0.5rem;
        width: 23rem;
        .slot-date-button {
          background: $default-white-color 0% 0% no-repeat padding-box;
          border: 1px solid $background-primary-color;
          border-radius: get_8_pixel_box(0.75);
          opacity: 1;
          margin-right: 0.5rem;
        }
      }
    }
    .slots-parent {
      padding-bottom: 1rem;
      .header-parent {
        display: flex;
        align-items: center;
      }
      .slots-availability-text {
        font-family: $text-font-regular;
        font-size: 1.2rem;
        letter-spacing: 0.27px;
        color: $font-secondary-color;
        opacity: 1;
        margin-left: 0.5rem;
      }
      .not-available-text {
        font-family: $text-font-regular;
        font-size: 1.15rem;
        letter-spacing: 0.27px;
        color: $font-secondary-color;
        opacity: 1;
        margin-top: 0.5rem;
      }
      .slots-container {
        margin: 0.5rem 0;
        .slot-time-text {
          font-family: $text-font-regular !important;
          font-size: 1rem;
          letter-spacing: 0.24px;
          color: $font-secondary-color;
          opacity: 1;
          margin-left: 0.5rem;
          border: 1px solid $border-tertiary-color;
          margin-top: 0.5rem;
        }
        .border-button {
          border: 1px solid $app-primary-color;
          background-color: $app-primary-color;
          color: $default-white-color;
        }
      }
      .expanded-button {
        background: none;
        border: 0;
        color: $sub-tabs-selected-link-color;
        cursor: pointer;
        padding: 0;
        font: normal normal bold 1rem/1.5rem $text-font-regular;
        letter-spacing: 0.013rem;
        padding-left: 0.5rem;
      }
    }
  }
  .css-3ok8xw {
    margin: 0 !important;
    width: 100%;
  }
  .MuiCalendarPicker-root {
    width: 100%;
  }
  .css-8v7him {
    padding: 0;
  }
  .PrivatePickersFadeTransitionGroup-root {
    text-align: center;
    font: normal normal bold 1rem/1.5rem $text-font-regular;
    letter-spacing: 0;
    color: $font-tertiary-color;
    opacity: 1;
  }
  .css-karnbp {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .MuiTypography-caption {
    margin: 0 9px;
    text-align: center;
    font: normal normal 600 1.15rem/1.3rem $text-font-regular;
    letter-spacing: 0;
    color: $font-primary-color;
    opacity: 1;
  }
  .css-agq55g {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    .cell {
      width: 15%;
    }
  }

  .MuiPickersDay-root {
    text-align: center;
    font: normal normal normal 1.15rem/1.4rem $text-font-regular;
    letter-spacing: 0;
    color: $date-color;
    opacity: 1;
    // width: 3.5rem;
    margin: 0 9px;
  }
  .css-2iy3mj {
    min-height: 13rem;
  }
  .PrivatePickersCalendarHeader-label-7 {
    font-weight: 600;
    font-size: 1.2rem;
  }
  .divider-container {
    border: 1px solid $white-color-1;
    margin: 0.5rem 0;
  }
  .continue-booking-button {
    width: 100%;
    font-size: 1.15rem;
    font-family: $text-font-regular;
    height: 2.35rem;
    border-radius: get_8_pixel_box(0.5);
    font-weight: 600;
  }
}
.menu-item-bg {
  background: $light-blue-color-1 !important;
}
.menu-item-hospital {
  text-align: left;
  font: normal normal normal 1rem/1.4rem $text-font-regular;
  letter-spacing: 0;
  color: $font-primary-color;
  opacity: 1;
  line-height: 2rem;
}
.menu-item-branch {
  text-align: left;
  font: normal normal normal 1rem/1.2rem $text-font-regular;
  letter-spacing: 0;
  color: $grey-color-3;
  opacity: 1;
}
.css-19gixdv-MuiPaper-root-MuiPopover-paper {
  width: 20% !important;
}
// .PrivatePickersCalendar-root-17 {
//   min-height: 15rem !important;
// }
