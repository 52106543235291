@import '../../../style/variables.scss';

.forums-question-component-wrapper {
  .forums-question-component-container {
    background: $default-white-color 0% 0% no-repeat padding-box;
    border-radius: 0.75rem;
    border: 1px solid $white-color-1;
    opacity: 1;
    padding: 1rem;
    .section-1 {
      .ques-title {
        font-size: 1.4rem;
        color: $font-primary-color;
        opacity: 1;
        font-family: $app_secondary_font_regular;
        font-weight: 600;
        letter-spacing: 0.016rem;
        margin-bottom: 0.7rem;
        text-align: left;
        text-transform: none !important;
      }
      .ques-title-2 {
        font-size: 1rem;
      }
    }
    .section-2 {
      margin-top: 0.2rem;
      .ques-by {
        font-size: 1rem;
        color: $font-primary-color;
        opacity: 1;
        font-family: $text-font-regular;
        letter-spacing: 0.011rem;
        margin-right: 0.3rem;
      }
      .ques-name {
        font-size: 1rem;
        color: $app-primary-color;
        opacity: 1;
        font-family: $text-font-regular;
        letter-spacing: 0.011rem;
        margin-right: 0.3rem;
        font-weight: 600;
      }
      .ques-name-2 {
        font-size: 0.8rem;
      }
      .ques-date {
        font-size: 1rem;
        color: $grey-color-3;
        opacity: 1;
        font-family: $text-font-regular;
        letter-spacing: 0.011rem;
      }
      .ques-date-2 {
        font-size: 0.8rem;
      }
      .left-img-sec {
        width: 1rem;
        height: 1rem;
        margin-right: 0.4rem;
      }
      .reply-section {
        justify-content: flex-end;
        cursor: pointer;
        .reply-img-sec {
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 0.4rem;
        }
        .reply-txt {
          font-size: 1.3rem;
          color: $app-primary-color;
          opacity: 1;
          font-family: $text-font-regular;
        }
      }
    }
    .section-3 {
      margin-top: 1.1rem;
      .ques-description {
        font-size: 1.22rem;
        color: $font-primary-color;
        opacity: 1;
        font-family: $text-font-regular;
        letter-spacing: 0.013rem;
        line-height: 2rem;
      }
      .ques-description-2 {
        font-size: 1rem;
        line-height: 1.75rem;
      }
    }

    .clamp-lines__button {
      background: none;
      border: 0;
      color: $app-primary-color;
      cursor: pointer;
      padding: 0;
      font: normal normal bold 1rem/1.5rem $text-font-regular;
      letter-spacing: 0.2px;
    }

    .clamp-lines__button:hover {
      border-bottom: 1px solid $app-primary-color;
    }
  }
  .secondary-color {
    background: $grey-color-5 0% 0% no-repeat padding-box;
    border: 0;
    margin-bottom: 0.75rem;
  }
}
