.confirm-container {
  flex-wrap: nowrap;
  flex-direction: column;
  // width: 30rem !important;
}
.headerTitle {
  display: flex;
  flex-direction: column;
}
.subTitle {
  color: grey !important;
  font-size: 0.875rem !important;
  font-weight: 500;
  padding: 0.3125rem 0rem;
}
.confirmedCard {
  border: 0.0625rem solid lightgrey;
  border-radius: 0.25rem;
  padding: 2rem;
}
.confirmedTag {
  background-color: lightgreen;
  color: darkgreen;
  padding: 0.125rem 0.375rem;
  align-self: center;
  font-size: 0.8rem;
  border-radius: 0.125rem;
  margin-top: -1.25rem !important;
  margin-left: 3.125rem !important;
}
.color {
  color: darkgreen;
}

.date-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 1.25rem;
  padding-top: 0.3125rem;
}

.date-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.9375rem;
  background-color: #eee;
  padding: 0.3125rem;
  border-radius: 0.3125rem;
}

.date-text {
  color: lightgreen !important;
  font-weight: bold !important;
  font-size: 1.2rem !important;
}

.date-month-text {
  font-weight: bold;
}

.confirm {
  // width: 6.25rem;
  // height: 6.25rem;
  font-size: 3rem;
  // margin: 1.25rem;
}

.header {
  justify-content: flex-end;
  align-items: center;
}

.confirm-grid {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.doctor-grid {
  display: flex;
  flex-direction: column;
}

.doctor-box {
  display: flex;
  justify-content: space-between;
  // width: 20rem;
}

// .confirmed-text {
//   margin-top: 0.625rem;
// }
