@import '../../../style/variables.scss';

.consultation-fees-wrapper {
  .consultation-fees-container {
    .consultation-fees-content {
      .fee-section {
        .img-sec {
          width: 2rem;
          height: 2rem;
        }
        .title {
          font-size: 1.1rem;
          color: $font-primary-color;
          opacity: 1;
          font-family: $text-font-regular;
          font-weight: 600;
        }
      }
      .row-container {
        .subtitle {
          font-size: 1.15rem;
          color: $doctor-title-color;
          font-family: $text-font-regular;
          line-height: 2rem;
        }
      }
    }
  }
}
