@import '../../../../style/variables.scss';

.appointment-confirmation-popup {
  background: $default-white-color 0% 0% no-repeat padding-box !important;
  border-radius: 0.45rem !important;
  opacity: 1 !important;
  max-width: 37rem !important;
  max-height: calc(100% - 0.5rem) !important;
  padding: 3rem 1.5rem;

  .popup-close-icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .appointment-avatar {
    background: $app-primary-color;
  }

  .popup-title {
    font-weight: 600;
    font-size: 1.1rem;
    color: $font-secondary-color;
    line-height: 1.75rem;
  }

  .popup-sub-title {
    font-size: 1rem;
    color: $or-text-color;
    line-height: 1.75rem;
  }

  .divider {
    margin: 2rem 0;
    opacity: 0.2;
    border: 1px solid $or-text-color;
  }

  .message-text {
    font-weight: 300;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: $font-secondary-color;
  }

  .appointment-cancel-button {
    height: 3rem;
    border-radius: 0.4rem;
    background: $vc-cancel-button-bg-color;
    width: 100%;
    font-weight: 600;
    font-size: 1.15rem;
    color: $app-primary-color;
  }

  .css-1y6f8wu-MuiDialogContent-root {
    padding-top: 0px !important;
  }
}
