@import '../../../../style/variables.scss';

.secondary-common-dialog-title-wrapper {
  .header-text {
    text-align: left;
    font: normal normal normal 1.4rem/2rem $text-font-regular;
    color: $font-primary-color;
  }

  .person-doctor-header {
    font: normal normal bold 1.5rem/2rem $text-font-regular;
    color: $font-primary-color;
    opacity: 1;
  }

  .person-doctor-sub-title {
    font-size: 1.2rem;
    padding-left: 1.2rem;
  }
}
