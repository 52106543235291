@import '../../../../../../../../src/style/variables.scss';

.patient-card-wrapper {
  border-radius: 0.625rem;
  background-color: $default-white-color;
  padding: 0.1rem;
  border: 0.0625rem solid $border-color-box-2;

  cursor: pointer;
  margin-bottom: 0.625rem;
  padding-top: 0.1rem;
  padding-bottom: 0.3rem;

  .name {
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: capitalize;
  }
  .category {
    font-size: 0.75rem;
    color: $or-text-color;
    text-transform: capitalize;
  }

  .doctor-name {
    margin-left: 0.5rem;
    font-weight: 600;
    font-size: 0.7rem;
    color: $virtual-clinic-doctor-patient-title-color;
  }

  .status-dot-grid {
    font-size: 0.75rem;
    font-family: $app_primary_font_regular;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0.4rem;

    .status-name {
      font-size: 0.8rem;
      margin-top: 0.25rem;
    }

    .status-dot {
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      margin-left: 0.25rem;
      margin-top: 0.25rem;
    }
  }

  .prescription-button {
    color: $prescription-color;
    background-color: $prescription-background-color;
    border-radius: 0.9375rem;
    font-size: 0.875rem;
    margin-right: 0.2rem;
  }

  .provide-vaccine-button {
    color: $video-status-color;
    background-color: $history-online-status;
    border-radius: 0.9375rem;
    font-size: 0.875rem !important;
    margin-top: -0.125rem;
  }

  .change-font-size {
    font-size: 0.625rem !important;
    // padding-right: 0.2rem !important;
    // padding-left: 0.2rem !important;
  }
  .checkedin-button {
    background-color: $video-status-color;
    color: $default-white-color;
    padding: 0.1rem 0.5rem;
  }
  .checkedin-button:hover {
    background-color: $video-status-color;
    color: $default-white-color;
  }
  .checkedin-button:focus {
    background-color: $video-status-color;
  }

  .billing-button {
    background-color: $button-light-color-1;
    color: $app-primary-color;
    padding: 0.1rem 0.5rem;
  }

  .time {
    font-size: 0.875rem;
    color: $font-secondary-color;
    text-align: center;
  }

  .time1 {
    color: $or-text-color;
    font-size: 0.875rem;
    text-align: center;
  }

  .online-offline-grid {
    text-align: end;
  }
  .online-offline {
    font-size: 0.875rem;
    color: $video-status-color;
  }
}

.border-class {
  border: 0.1325rem solid $app-primary-color;
  background-color: $patient-card-background-color;
  box-shadow: 0px -13px 13px rgba(193, 220, 228, 0.36),
    0px 13px 11px rgba(193, 220, 228, 0.25);
  margin: 0.3125rem;
}

.cancel-appointment-wrapper {
  .dialog {
    width: 31.25rem;
    padding-bottom: 1.25rem;
  }
  .addservice {
    flex-direction: row;
    justify-content: space-between;
  }

  .add {
    padding-left: 0.3125rem;
  }

  .clos {
    padding-left: 3.125rem;
  }

  .fontback {
    font-family: $app_primary_font_regular;
    font-weight: 600;
    font-size: 1rem;
  }
  .add-bt {
    display: flex;
    justify-content: center;
  }
  .add-wt {
    width: 80%;
  }
  .conform {
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .conformText {
    text-align: center;
    font-family: $app_primary_font_regular;
    font-weight: 600;
    font-size: 1.25rem;
  }
  .add-clik {
    margin-left: 2rem;
    text-align: center;
  }
}
.billing-button-grid {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
}

// doctor card styles
.doctor-card-wrapper {
  background-color: $default-white-color;
  border-radius: 0.5rem;
  padding: 0.5rem;
  padding-left: 0rem;
  .render-day-date-grid {
    border: 0.063rem solid $person-web-timeline-patient-card-divider;
    border-top: none;
    border-left: none;
    border-bottom: none;
  }

  .day-date-main-grid {
    padding: 0.58rem 0rem;
    text-align: center;

    .day-date-grid {
      font-size: 1.4rem;
      color: $video-status-color;
      margin-top: 0.4rem;
      margin-bottom: 0.4rem;
    }
  }

  .render-doctor-details-main-grid {
    .doctor-details-main-grid {
      padding-left: 1rem;
      text-transform: capitalize;

      .doctor-name-grid {
        margin-bottom: 0.5rem;
        font-size: 1rem;
        font-weight: 600;
      }
      .doctor-specialization-grid {
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
      }

      .doctor-hospital-grid {
        font-weight: 600;
        margin-top: 0.5rem;
      }
    }
  }

  .render-appointment-status-main-grid {
    .doctor-appointment-status-main-grid {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .doctor-appointment-status-name-grid {
        display: flex;

        justify-content: flex-end;
        .appointment-status-dot {
          padding-right: 1rem;
        }
      }

      .doctor-appointment-status-grid {
        margin-top: 0.3rem;
        margin-bottom: 2rem;
        color: $video-status-color;
        background-color: $background-color6;
        width: fit-content;
        text-align: end;
        margin-right: 2.3rem;
        margin-top: 0.5rem;
      }
    }
  }
}
