@import '../../../style/variables.scss';
@import '../../../style/functions.scss';

.hospital-view-description-wrapper {
  .hospital-view-description-container {
    border: 1px solid $hospital-view-border-color;
    border-radius: 10px;
    background: $default-white-color 0% 0% no-repeat padding-box;
    padding: 1.5rem;

    .hospital-view-description-content {
      .container-child-1 {
        .img {
          width: 11rem;
          height: 11rem;
          border-radius: 0.5rem;
        }
      }

      .container-child-2 {
        .title-1 {
          color: $hospital-title-color;
          font: normal normal bold 1.4rem/3.5rem $text-font-regular;
          text-transform: capitalize;
          display: flex;
          .qrcodeGrid {
            margin-left: 1.25rem;
            cursor: pointer;
            .Qrimg {
              width: 2rem;
              height: 2rem;
              border-radius: 0.5rem;
            }
          }
        }
        .title-2 {
          font-size: 1.15rem;
          color: $hospital-title-color;
          opacity: 1;
          font-family: $text-font-regular;
          line-height: 2rem;
          margin-top: 0.375rem;
        }
        .taglineFont {
          font-size: 1rem;
          color: $hospital-title-color;
          opacity: 1;
          font-family: $text-font-regular;
          font-weight: bold;
          margin-top: -0.375rem;
        }
        .hospital-name-link-text {
          text-decoration: underline;
          cursor: pointer;
        }
        .bottom-section {
          margin-top: 1rem;
          justify-content: space-between;
          .left-title {
            font-size: 1.2rem;
            color: $hospital-title-color;
          }
          .right-title {
            font-size: 1.2rem;
            color: $green-color;
            opacity: 1;
            margin-left: 0.2rem;
          }
          .mainQrGrid {
            .qrcodeGrid {
              margin-left: 1.25rem;
              cursor: pointer;
              .Qrimg {
                width: 3rem;
                height: 3rem;
                border-radius: 0.5rem;
                border: 0.0625rem solid $border-right-color;
              }
            }
            .scanQrFont {
              text-align: left;
              font: normal normal normal 14px/22px Nunito Sans;
              color: $placeholder-text-color;
              padding-right: 0.1875rem;
            }
          }
        }
      }
    }
  }
}
