@import '../../../style/variables.scss';
@import '../../../style/functions.scss';

.doctor-card-wrapper {
  .doctor-card-container {
    background: $default-white-color 0% 0% no-repeat padding-box;
    border-radius: get_8_pixel_box(1.25);
    border: 1px solid $doctor-view-border-color;
    .doctor-card-content {
      padding: 0.85rem;
      .left-section-child-section {
        .left-section-child-1 {
          .img {
            width: 8rem;
            height: 8rem;
            border-radius: get_8_pixel_box(1);
          }
        }
        .left-section-child-2 {
          .doctor-name-link {
            text-align: left;
            text-decoration: underline;
            text-transform: capitalize;
            font: normal normal 600 1.1rem/1.5rem $text-font-regular;
            letter-spacing: 0px;
            color: $font-primary-color;
            opacity: 1;
          }

          .title-1 {
            font-size: 1.4rem;
            color: $font-primary-color;
            opacity: 1;
            font-family: $text-font-regular;
            font-weight: bold;
            text-transform: capitalize;
          }

          .title-2 {
            font-size: 1.15rem;
            color: $doctor-title-color;
            opacity: 1;
            margin-top: 0.8rem;
            font-family: $text-font-regular;
            font-weight: normal;
            text-transform: capitalize;
          }
          .container-child-2 {
            .title-3 {
              font-size: 1.15rem;
              color: $doctor-title-color;
              opacity: 1;
              margin-top: 0.8rem;
              font-family: $text-font-regular;
              display: flex;
              justify-content: space-between;
            }
          }
          .appointmentTypes {
            display: flex;
            .Inperson {
              .personVisitButton {
                background-color: $add-subjective-title-background-color;
                border-radius: 1.375rem;
                color: $default-black-color;
              }
            }
            .Remote {
              margin-left: 1.3125rem;
              .remoteVisitButton {
                background-color: $add-subjective-title-background-color;
                border-radius: 1.375rem;
                color: $default-black-color;
              }
            }
          }
          .title-4 {
            font-size: 1.15rem;
            color: $doctor-title-color;
            opacity: 1;
            margin-top: 0.8rem;
            font-family: $text-font-regular;
          }
          .more-clinics-link {
            margin-left: get_8_pixel_box(0.25);
            text-decoration: underline;
            font-size: 1.3rem;
            color: $font-primary-color;
            font-weight: 600;
          }
          .buttons-section {
            justify-content: space-between;
            margin-top: 1rem;
            .mainQrGrid {
              .qrcodeGrid {
                padding-left: 1.25rem;
                cursor: pointer;
                .Qrimg {
                  width: 3rem;
                  height: 3rem;
                  border-radius: 0.5rem;
                  border: 0.0625rem solid $border-right-color;
                }
              }
              .scanQrFont {
                text-align: left;
                font: normal normal normal 14px/22px Nunito Sans;
                color: $placeholder-text-color;
                padding-right: 0.1875rem;
              }
            }
            // margin-top: 1rem;
          }

          .consultation-button {
            background: $add-subjective-title-background-color 0% 0% no-repeat
              padding-box;
            border-radius: get_8_pixel_box(2.75);
            opacity: 1;
            color: $font-primary-color;
            font-size: 1.15rem;
            font-family: $text-font-regular !important;
            font-weight: normal;
            margin-right: 0.5rem;
            width: 12.5rem;
            height: 2.5rem;
          }

          .remote-button {
            width: 12.5rem;
          }
          .renderButtonsSmallGrid {
            .consultation-button-small {
              background: $add-subjective-title-background-color 0% 0% no-repeat
                padding-box;
              border-radius: get_8_pixel_box(2.75);
              opacity: 1;
              color: $font-primary-color;
              font-size: 12px;
              font-family: $text-font-regular !important;
              font-weight: 600;
              margin-right: 0.5rem;
              width: max-content;
              height: max-content;
            }

            .remote-button-small {
              margin-top: 5px;
              width: max-content;
            }
          }
        }

        .medication-button {
          border-radius: get_8_pixel_box(0.75);
          opacity: 1;
          color: $font-primary-color;
          font-family: $text-font-regular;
          font-weight: 600;
          margin-left: 1rem;
          background: $button-background-color 0% 0% no-repeat padding-box;
          border: 1px solid $button-border-color;
          text-transform: capitalize;
          font-size: 0.8rem;
        }
      }
    }
  }
}
