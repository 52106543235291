@import '../../../../style/variables.scss';

.email-update-wrapper {
  .title-wrapper {
    padding-top: 2rem !important;
  }
  .action-wrapper {
    padding: 1rem !important;
  }
  .header-text {
    font-size: 1.3rem !important;
  }
}
