@import '../../../../style/variables.scss';
@import '../../../../style/functions.scss';

.doctor-view-component-wrapper {
  width: 100%;
  .doctor-view-component-container {
    .tabs-section {
      margin-top: 1rem;
      background: $default-white-color 0% 0% no-repeat padding-box;
      border: 1px solid $hospital-view-border-color;
      border-radius: 10px;
      opacity: 1;
      width: 100%;
      .body-section {
        padding: 2rem;
      }
    }
  }
}
