@import '../../../style/variables.scss';
@import '../../../style/functions.scss';

.more-clinics-header {
  text-align: left;
  font: normal normal bold 1.2rem/1.5rem $text-font-regular;
  letter-spacing: 0px;
  color: $font-primary-color;
  opacity: 1;
  padding: 1rem;
}

.MuiDialog-paperWidthLg {
  background: $default-white-color 0% 0% no-repeat padding-box !important;
  border-radius: 1.5rem !important;
  opacity: 1;
}
