@import '../../../style/variables.scss';

.forums-description-section {
  background: $blue-1;
  padding: 1.5rem;
  border-radius: 1.25rem;
  .section-1 {
    .title {
      font-size: 2.3rem;
      color: $font-primary-color;
      opacity: 1;
      font-family: $text-font-regular;
      font-weight: bold;
      letter-spacing: 0.031rem;
    }
    .subtitle {
      font-size: 1.2rem;
      color: $font-primary-color;
      opacity: 1;
      letter-spacing: 0.014rem;
      margin-top: 1rem;
    }
    .btn-sec {
      text-transform: initial;
      margin-top: 1.8rem;
      .MuiButton-label {
        font-size: 1.2rem !important;
      }
    }
  }
  .section-2 {
    text-align: end;
    .forums-icon {
      width: 100%;
      height: 100%;
    }
  }
}

.forums-description-section-2 {
  border-radius: 6px 6px 0 0;
  margin-top: 0.25rem !important;
  margin-left: 0 !important;
  width: 100% !important;
  .section-1 {
    .title-2 {
      font-size: 1.2rem;
    }
    .subtitle-2 {
      font-size: 1rem;
      margin-top: 0.5rem;
    }
  }
}
