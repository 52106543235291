@import '../../../../../../src/style/variables.scss';
.create-appointment-modal-wrapper {
  .appointment-title {
    font-size: 1.25rem;
  }

  .doctor-patient-title {
    font-weight: 600;
    font-size: 1.15rem;
    color: $virtual-clinic-doctor-patient-title-color;
    padding: 1rem 0;
  }

  .appointment-data {
    padding-left: 26.25rem;
  }

  .compliant-box {
    border: 0.0625rem solid $grey-color-4;
    border-radius: 0.125rem;
    padding: 2;
    width: 100%;
    background-color: $hospital-view-background-color;
    ::placeholder {
      color: $or-text-color;
      // font-size: $text-tertiary-font-size;
      font-size: smaller !important;
      font-weight: $app-tertiary-font-weight !important;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $or-text-color !important;
      // font-size: $text-tertiary-font-size;
      font-size: smaller !important;
      font-weight: $app-tertiary-font-weight !important;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $or-text-color !important;
      // font-size: $text-tertiary-font-size;
      font-size: smaller !important;
      font-weight: $app-tertiary-font-weight !important;
    }
  }

  .create-appointment-modal {
    border-radius: 0.9375rem;
  }

  .create-appointment-container {
    max-width: 31.1875rem;
    max-height: 40.625rem;

    .appointment-containerTitle {
      flex-wrap: nowrap;
      flex-direction: column;
      width: 100%;
      padding: 0.9375rem;
      // height: 38.375rem;
      border-radius: 1.25rem;
      margin-bottom: 1.25rem;
    }
    .appointment-header {
      height: 100%;
    }

    .appointment-headerTitle {
      display: flex;
      flex-direction: row;
    }
    .appointment-subTitle {
      color: $font-other-color;
      margin: 0.625rem 0rem;
    }

    .appointment-type {
      justify-content: space-between;
      margin-right: 0.625rem;
      margin-left: 0.75rem;
    }

    .appointment-patient {
      // align-items: center;
      justify-content: space-between;
      // margin-top: 0.9375rem;
      .avatar {
        height: 5.3125rem;
        width: 5.3125rem;
        // margin: 0rem 0.625rem;
      }
    }
    .complaint-heading {
      padding-top: 0.625rem;
    }
    .appoint-types {
      font-family: $app_primary_font_regular;
      font-weight: $app-tertiary-font-weight;
      font-size: $text-primary-font-size;
      color: $font-secondary-color;
    }
    .time-dates {
      font-family: $app_primary_font_regular;
      font-weight: $app-tertiary-font-weight-2;
      font-size: $text-secondary-font-size;
      color: $default-black-color;
    }
    .appoint-type-details {
      font-family: $app_primary_font_regular;
      font-weight: $app-tertiary-font-weight-2;
      font-size: $text-primary-font-size;
      color: $default-black-color;
    }

    .appointment-patient-text {
      font-size: 0.9rem;
      margin-top: 0.125rem;
    }

    .appointment-confirm-button {
      justify-content: center;
      margin: auto;
      width: 100%;
      margin-left: 0.3125rem;
      margin-top: 2.125rem;
    }
  }
  .button-confirm {
    height: 2.9375rem;
  }
  .options-list-schedular {
    height: 29rem;
  }
  .css-s1wmdt-MuiAutocomplete-listbox {
    height: 50vh;
    width: 100%;
  }
}
